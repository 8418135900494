const handleSubmit = (form: HTMLFormElement, formErrors: HTMLElement | null, onSuccess: () => void) => {
  form.addEventListener('submit', function(event) {
    event.preventDefault();
    const formData = new FormData(form);

    fetch(form.action, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.text())
    .then(data => {
      const responseHTML = document.createElement('div');
      responseHTML.innerHTML = data;
      const responseHTMLData = responseHTML.querySelector('.m-customer__response-data');
      const responseData = responseHTMLData ? JSON.parse(responseHTMLData.innerHTML) : null;

      if (responseData && !responseData.success) {
        if (formErrors) {
          const responseHTMLErrors = responseHTML.querySelector('.m-customer__errors');
          formErrors.innerHTML = responseHTMLErrors?.innerHTML || '';
        }
      } else {
        onSuccess();        
      }
    })
    .catch(error => {
      console.error('Error:', error);
      if (formErrors) formErrors.innerHTML = 'An error occurred. Please try again.';
    });
  });
};

export default {
  handleSubmit
};